import React from "react";
import {
  Footer,
  FrequentlyAskedQuestionComponent,
  HeroSection,
  HowItWorks,
  JoinWaitlistComponent,
  Navbar,
} from "../../Components";
import { Helmet } from "react-helmet";


const HomePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Agent Mushroom AI - Home</title>
        <meta name="description" content="Mushroom AI Home Page" />
      </Helmet>
      <Navbar />
      <HeroSection />
      <HowItWorks />
      <FrequentlyAskedQuestionComponent />
      <JoinWaitlistComponent />
      <Footer />
    </>
  );
};

export { HomePage };
