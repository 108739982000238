import { questionType } from ".";

export const questions: questionType[] = [
  {
    question: "What is Mushroom?",
    answer:
      "Mushroom is a tool designed to help software developers, product managers, and product designers ensure that features implemented in development environments match their design specifications in Figma. It compares design elements such as pixels, colors, typography, and layout, and generates alerts for any inconsistencies detected.",
  },
  {
    question: "How does Mushroom work?",
    answer:
      "Mushroom integrates with both Figma and your local, development, or staging environments. It captures design specifications from Figma and implementation details from your environment, compares them, and generates reports highlighting any inconsistencies.",
  },
  {
    question: "How do I integrate Mushroom with Figma?",
    answer:
      "Mushroom provides a Figma plugin that allows you to select specific design components within a Figma file and capture their specifications. Detailed instructions for integrating the plugin can be found in our documentation.",
  },
  {
    question: "Does Mushroom offer an API?",
    answer:
      "Yes, Mushroom provides an API that allows external tools and services to interact with the comparison engine. This enables seamless integration with your existing workflows and tools.",
  },
];
