import React from "react";
// import * as yup from "yup";
// import { useFormik } from "formik";
// import { FirebaseService } from "../../Services/firebase";
// import { availableRoles } from "./waitlistRoles";
import { Spinner} from "../index";
// import toast from "react-hot-toast";
// import {
//   InputBorderType,
//   WaitlistFormData,
//   WaitlistRoles,
// } from "../../Types/globalInterfaces";
import { useNavigate } from "react-router-dom";

// const validationSchema = yup.object({
//   name: yup.string().required("Name is required"),
//   email: yup.string().email().required("Email is required"),
//   role: yup.string().required("Role is required"),
// });

const JoinWaitlistComponent: React.FC = () => {
  const loading = false;
  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     email: "",
  //     role: "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: () => {},
  // });

  const navigate = useNavigate();
  const handleNavigateToWaitlist = () => {
    navigate("/waitlist");
  };
  // const handleSaveTheWaitlist = async () => {
  //   setLoading(true);
  //   const firebase = new FirebaseService();
  //   try {
  //     const requestBody: WaitlistFormData = {
  //       name: formik.values.name,
  //       email: formik.values.email,
  //       role: formik.values.role as WaitlistRoles,
  //     };
  //     await firebase.saveWaitlistData(requestBody);
  //     toast.success("Successfully added to the waitlist");
  //   } catch (error) {
  //     toast.error("Error while adding to the waitlist");
  //     console.log(error);
  //   }

  //   setLoading(false);
  // };

  // const formikTouched: any = formik.touched;
  // const formikErrors: any = formik.errors;

  // const FormError = ({ name }: any) =>
  //   formikTouched[name] && formikErrors[name] ? (
  //     <p className="text-red-500 text-sm mt-[-2px]">{formikErrors[name]}</p>
  //   ) : null;

  // const getFormError = (name: string) =>
  //   formikTouched[name] && formikErrors[name]
  //     ? InputBorderType.ERROR
  //     : InputBorderType.DEFAULT;

  return (
    <div
      className="bg-primary text-white px-[8%] py-14 flex sm:flex-col sm:gap-10 mt-20"
      id="join-waitlist"
    >
      <div className="w-[50%] flex flex-col gap-2 justify-center sm:w-full ">
        <h1 className="text-4xl">Stay in the loop!</h1>
        <p className="text-base text-grey">
          It's friendly, fast and lightweight.
        </p>
      </div>

      <div className="w-[40%] sm:w-full">
        <form>
          {/* <div className="flex gap-4 flex-col">
            <div className="flex gap-4 sm:flex-col">
              <div className="flex flex-col gap-2 w-1/2 sm:w-full">
                <label className="text-sm"> Your name</label>

                <InputComponent
                  name="name"
                  onChange={formik.handleChange}
                  placeholder="Enter your name"
                  borderType={getFormError("name")}
                />
                <FormError name="name" />
              </div>
              <div className="flex flex-col gap-2 w-1/2 sm:w-full">
                <label className="text-sm"> Your Email</label>
                <InputComponent
                  name="email"
                  onChange={formik.handleChange}
                  placeholder="Enter your Email ID"
                  borderType={getFormError("email")}
                />
                <FormError name="email" />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-sm">Your role</label>
              <div className="flex gap-3 sm:flex-col">
                {availableRoles.map((role: string, index: number) => {
                  return (
                    <div className="flex gap-1 text-center items-center">
                      <input
                        key={index}
                        type="checkbox"
                        className={`custom-checkbox cursor-pointer ${
                          getFormError("role") === InputBorderType.ERROR
                            ? "error"
                            : "border-gray-300"
                        }`}
                        name="role"
                        value={role}
                        onChange={() => {
                          formik.setFieldValue("role", role);
                        }}
                        checked={formik.values.role === role}
                      />
                      <label className="cursor-pointer text-sm">{role}</label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="h-fit">
              <FormError name="role" />
            </div>
          </div> */}
          <button
            onClick={handleNavigateToWaitlist}
            disabled={loading}
            className="disabled:cursor-not-allowed flex justify-center items-center px-4 py-2 rounded-full font-sm focus:outline-none bg-white w-full mt-2 text-blue border-blue border-2"
          >
            {!loading ? (
              "Join waitlist"
            ) : (
              <Spinner
                color="blue"
                className="h-6 w-7 animate-spin text-red-500"
              />
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export { JoinWaitlistComponent };
