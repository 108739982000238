import { FC, ReactNode } from "react";

interface FeatureListCardProps {
  title: string;
  description: string;
  icon: ReactNode;
}

const FeatureListCard: FC<FeatureListCardProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div className="rounded-lg flex flex-col gap-7 bg-[#F9FBFF] border border-[#E3EBFD] p-5 w-full">
      <div className="flex justify-center w-full items-center min-h-[200px]">
        {icon}
      </div>
      <div>
        <p className="text-2xl font-semibold text-primary justify-start items-start text-start sm:text-xl">
          {title}
        </p>
        <p className="text-base text-grey mt-2">{description}</p>
      </div>
    </div>
  );
};

export { FeatureListCard };
