import { useNavigate } from "react-router-dom";
import { OnlyMushroomLogo, Button } from "../index";
import { FC } from "react";

interface NavbarProps {
  className?: string;
}

const Navbar: FC<NavbarProps> = (props) => {
  const { className } = props;
  const navigate = useNavigate();

  return (
    <nav
      className={`flex justify-between px-10 py-5 items-center sm:px-5 ${className}`}
    >
      <div
        onClick={() => {
          navigate("/");
        }}
        className="flex text-center justify-center items-center gap-2 cursor-pointer"
      >
        <OnlyMushroomLogo className="h-9 w-9" />
        <p className="font-semibold text-lg">Mushroom</p>
      </div>
      <Button
        onClick={() => {
          navigate("/waitlist");
        }}
        styleType="outline"
      >
        Join Waitlist
      </Button>
    </nav>
  );
};

export { Navbar };
