import { FC } from "react";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";

const HeroSection: FC = () => {
  const navigate = useNavigate();
  const handleNavigateToWaitlist = () => {
    navigate("/waitlist");
  };

  return (
    <div
      style={{
        backgroundImage: "url('/hero.png')",
        backgroundOrigin: "border-box",
      }}
      className="bg-cover bg-center bg flex flex-col min-h-[900px] justify-center items-center sm:px-5"
    >
      <div className="flex flex-col items-center gap-2 w-1/2 sm:px-5 text-center sm:w-full md:w-8/12">
        <h1 className="font-medium text-7xl text-primary sm:text-6xl">
          Automate design audits
        </h1>
        <p className="text-grey text-[20px] w-[80%] sm:w-full">
          Our AI agent compares your frontend code with designs in figma to
          identify and fix gaps.
        </p>
        <Button
          styleType="primary"
          className="text-base font-primary font-semibold px-6 py-3"
          onClick={handleNavigateToWaitlist}
        >
          Join Waitlist
        </Button>
      </div>
      <div className=" mt-10 items-center flex justify-center">
        <img src="/hero-section.png" alt="" className="w-[90%] h-[90%] sm:h-full sm:w-full" />
      </div>
    </div>
  );
};

export { HeroSection };
