import React from "react";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  styleType?: "primary" | "secondary" | "outline";
  className?: string;
  type?: "button" | "submit" | "reset";
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  styleType = "primary",
  className,
  type = "button",
  ...props
}) => {
  const baseStyles = `px-4 py-2 rounded-full font-sm focus:outline-none flex items-center justify-center ${className}`;

  const styles = {
    primary: `${baseStyles} bg-blue text-white hover:bg-primary-dark`,
    secondary: `${baseStyles} bg-secondary hover:bg-secondary-dark`,
    outline: `${baseStyles} border border-blue text-blue bg-transparent border-2`,
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={styles[styleType]}
      {...props}
    >
      {children}
    </button>
  );
};

export { Button };
