import React from "react";
import { EclipseIcon } from "../Icons";

interface BacgroundProps {
  children: React.ReactNode;
  className?: string;
}

const Background: React.FC<BacgroundProps> = (props) => {
  const { children } = props;
  return (
    <div className="font-light px-4 py-3 rounded-full text-center bg-[#F1F1FF] flex items-center gap-2">
      <EclipseIcon />
      {children} <EclipseIcon />
    </div>
  );
};

export { Background };
