import React, { useState } from "react";
import { Background } from "../Background";
import { questions } from "./questions";
import { FAQList } from "../Common/FAQList";

export interface questionType {
  question: string;
  answer: string;
}

const FrequentlyAskedQuestionComponent = () => {
  const [openedQuestion, setOpenedQuestion] = useState<number[]>([]);

  const handleOpenQuestion = (index: number) => {
    if (openedQuestion.includes(index)) {
      setOpenedQuestion(openedQuestion.filter((item) => item !== index));
    } else {
      setOpenedQuestion([...openedQuestion, index]);
    }
  };

  return (
    <div className="px-10 sm:px-5 w-full mt-20">
      <div className="flex justify-center items-center min-w-full flex-col gap-4">
        <Background>
          <p className="uppercase text-base text-primary">
            Your questions, answered here!
          </p>
        </Background>
        <p className="text-primary font-medium text-5xl text-center sm:text-4xl">
          Frequently Asked Questions
        </p>
      </div>
      <div className="flex flex-col mt-10 px-20 sm:px-0">
        {questions.map((question : questionType, index) => {
          return (
            <FAQList
              key={index}
              question={question.question}
              answer={question.answer}
              isOpen={openedQuestion.includes(index)}
              onClick={() => handleOpenQuestion(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export { FrequentlyAskedQuestionComponent };
