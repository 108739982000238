import React from "react";
import { FAQMinusIcon, FAQPlusIcon } from "../Icons";

interface FAQListProps {
  isOpen: boolean;
  question: string;
  answer: string;
  onClick: () => void;
}

const FAQList: React.FC<FAQListProps> = ({
  isOpen,
  question,
  answer,
  onClick,
}) => {
  return (
    <div className="border-b border-[#EDF3FF]">
      <div
        className="flex justify-between items-center py-4  cursor-pointer"
        onClick={onClick}
      >
        <p className="text-primary font-semibold text-lg">{question}</p>
        {isOpen ? (
          <FAQMinusIcon className="h-6 w-6 text-primary" />
        ) : (
          <FAQPlusIcon className="h-6 w-6 text-primary" />
        )}
      </div>
      <div
        className={`w-[95%] overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "max-h-[100px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="pb-2">
          <p className="text-grey font-light text-sm">{answer}</p>
        </div>
      </div>
    </div>
  );
};

export { FAQList };
