import {
  FeatureListCard,
  EasyCompareIcon,
  FastCompareIcon,
  WorkFlowCompareIcon,
  Background,
} from "..";

const HowItWorks: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center mb-10 px-10 sm:px-5">
      <div className="flex flex-col justify-center items-center text-center gap-4 w-full lg:w-1/2 ">
        <Background>
          <p className="text-primary text-base">THIS IS HOW IT WORKS</p>
        </Background>
        <p className="text-5xl font-medium text-primary mt-2 sm:text-4xl">
          We streamline checks and resolve inconsistencies
        </p>
      </div>

      <div className="flex justify-center w-full mt-16">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full max-w-7xl xl:grid-cols-3 extralg:grid-cols-3">
          <FeatureListCard
            title="Easy compare"
            description="Overlay your feature with the figma design to easily identify discrepancies"
            icon={<EasyCompareIcon className="h-12 w-12 text-primary" />}
          />
          <FeatureListCard
            title="Fast"
            description="Overlay your feature with the figma design to easily identify discrepancies"
            icon={<FastCompareIcon className="h-12 w-12 text-primary" />}
          />
          <FeatureListCard
            title="Workflow integration"
            description="Our plugins work on chrome and figma so that you can easily select features you want to compare"
            icon={<WorkFlowCompareIcon className="h-12 w-12 text-primary" />}
          />
        </div>
      </div>
    </div>
  );
};

export { HowItWorks };
