// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyC2svCMXjxGcHwCUqgleb3oPfZ8deWiQdE",
  authDomain: "mushroom-5f772.firebaseapp.com",
  databaseURL: "https://mushroom-5f772-default-rtdb.firebaseio.com",
  projectId: "mushroom-5f772",
  storageBucket: "mushroom-5f772.appspot.com",
  messagingSenderId: "828809466726",
  appId: "1:828809466726:web:61a6e61938cd3ad8d52ea7",
  measurementId: "G-2VEG0BWB0E",
};
