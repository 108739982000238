import React from "react";
import { OnlyMushroomLogo } from "../Icons";

const Footer = () => {
  return (
    <div className="flex flex-col px-[8%] sm:px-5 justify-start py-10 ">
      <div>
        <div className="flex text-center items-center gap-2">
          <OnlyMushroomLogo className="h-9 w-9" />
          <p className="font-semibold text-lg">Mushroom</p>
        </div>
      </div>

      <div className="flex justify-between items-center mt-6 text-grey text-sm sm:justify-start sm:items-start sm:flex-col-reverse sm:gap-4">
        <p>© {new Date().getFullYear()} Mushroom. All rights reserved.</p>
        {/* <div className="flex gap-6 sm:flex-col sm:gap-1">
          <p className="cursor-pointer">Privacy Policy</p>
          <p className="cursor-pointer">Terms of Service</p>
        </div> */}
      </div>
    </div>
  );
};

export { Footer };
