import { useState } from "react";
import {
  Button,
  Footer,
  GreenCircleTickIcon,
  InputComponent,
  Navbar,
  Spinner,
} from "../../Components";
import * as yup from "yup";
import { useFormik } from "formik";
import { InputBorderType } from "../../Types/globalInterfaces";
import { FirebaseService } from "../../Services/firebase";
import { Helmet } from "react-helmet";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  companyName: yup.string().required("Company name is required"),
  companySize: yup.string().required("Company size is required"),
  otherInformation: yup.string(),
});

const WaitlistPage = () => {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      companyName: "",
      companySize: "1-5",
      otherInformation: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => handleSubmitForm(),
  });

  const handleSubmitForm = async () => {
    setLoading(true);
    try {
      const firebaseService = new FirebaseService();
      await firebaseService.saveWaitlistData(formik.values);
      setIsSuccess(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;

  const FormError = ({ name }: any) =>
    formikTouched[name] && formikErrors[name] ? (
      <p className="text-red-500 text-sm mt-[-2px]">{formikErrors[name]}</p>
    ) : null;

  const getFormError = (name: string) =>
    formikTouched[name] && formikErrors[name]
      ? InputBorderType.ERROR
      : InputBorderType.DEFAULT;

  return (
    <>
      <Helmet>
        <title>Mushroom AI - Waitlist</title>
      </Helmet>
      <Navbar className="bg-[#F9FBFF]" />
      <div className="">
        <div className="flex justify-center py-14 text-center bg-[#F9FBFF]">
          <h2 className="w-4/5 text-6xl  text-primary">
            AI agent that helps you ship pixel perfect features 
          </h2>
        </div>
        {!isSuccess ? (
          <div className="py-14 px-[9%] flex flex-col sm:px-5">
            <div className="flex flex-col gap-2 justify-center items-center sm:py-10">
              <h1 className="text-primary text-3xl font-semibold">
                Join Waiting List
              </h1>
              <p className="text-grey">
                We'll follow up via email to keep you updated.
              </p>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-5"
            >
              <div className="flex flex-col gap-4 w-[60%] sm:w-full">
                <p className="text-primary font-semibold text-xl">
                  Your Information
                </p>
                <div className="flex gap-4 w-full sm:flex-col">
                  <div className="flex flex-col gap-1 w-1/2 sm:w-full">
                    <label className="text-primary">Your name*</label>
                    <InputComponent
                      name="name"
                      onChange={formik.handleChange}
                      placeholder="Enter your name"
                      borderType={getFormError("name")}
                    />
                    <FormError name="name" />
                  </div>
                  <div className="flex flex-col gap-1 w-1/2 sm:w-full">
                    <label className="text-primary">Your work email*</label>
                    <InputComponent
                      name="email"
                      onChange={formik.handleChange}
                      placeholder="Enter your work email ID"
                      borderType={getFormError("email")}
                    />
                    <FormError name="email" />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4 w-[60%] sm:w-full">
                <p className="text-primary text-xl font-semibold">
                  Company Information
                </p>
                <div className="flex gap-4 w-full sm:flex-col">
                  <div className="flex flex-col gap-1 w-1/2 sm:w-full">
                    <label className="text-primary">Company name*</label>
                    <InputComponent
                      placeholder="Enter your company name"
                      name="companyName"
                      onChange={formik.handleChange}
                      borderType={getFormError("companyName")}
                    />
                    <FormError name="companyName" />
                  </div>
                  <div className="flex flex-col gap-1 w-1/2 sm:w-full">
                    <label className="text-primary">Company size*</label>
                    <select
                      className={`p-2 bg-transparent rounded-md text-inherit outline-none border transition-colors duration-300 ${
                        getFormError("companySize") === InputBorderType.ERROR
                          ? "border-red-500 focus:border-red-600 placeholder-red-400"
                          : "border-gray-300 focus:border-gray-400"
                      }`}
                      name="companySize"
                      onChange={formik.handleChange}
                    >
                      <option value="1-5">1-5</option>
                      <option value="5-10">5-10</option>
                      <option value="10-50">10-50</option>
                      <option value="100+">100+</option>
                    </select>
                    <FormError name="companySize" />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4 w-[60%] sm:w-full">
                <p className="text-primary text-xl font-semibold">
                  Other Information
                </p>
                <div className="flex gap-4 w-full">
                  <div className="flex flex-col w-full gap-1">
                    <label className="text-primary">
                      What are you hoping to get out of Mushroom?
                    </label>
                    <textarea
                      name="otherInformation"
                      className={` resize-none p-2 h-20 bg-transparent rounded-md text-inherit outline-none border transition-colors duration-300 ${
                        getFormError("otherInformation") ===
                        InputBorderType.ERROR
                          ? "border-red-500 focus:border-red-600 placeholder-red-400"
                          : "border-gray-300 focus:border-gray-400"
                      }`}
                      onChange={formik.handleChange}
                      placeholder="Enter your answer"
                    ></textarea>
                    <FormError name="otherInformation" />
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                styleType="primary"
                className="w-[30%] mt-5 py-4 sm:w-full"
              >
                {loading ? (
                  <Spinner
                    color="white"
                    className="h-6 w-8 animate-spin text-red-500"
                  />
                ) : (
                  "Join Waitlist"
                )}
              </Button>
            </form>
          </div>
        ) : (
          <div className="p-10 h-[60vh] flex justify-center items-center">
            <div className="flex flex-col gap-2 justify-center items-center">
              <GreenCircleTickIcon className="h-16 w-16" />
              <h1 className="text-primary text-3xl font-semibold text-center">
                Thank you for your interest !
              </h1>
              <p className="text-grey text-center">
                If you have more questions, feel free to ask jenil@agentmushroom.ai
              </p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export { WaitlistPage };
