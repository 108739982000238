import React from "react";
import { InputBorderType } from "../../Types/globalInterfaces";


interface InputProps {
  placeholder: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  borderType?: InputBorderType;
  [key: string]: any;
  className?: string;
}

const InputComponent: React.FC<InputProps> = ({
  placeholder,
  onChange,
  borderType = InputBorderType.DEFAULT,
  className,
  ...rest
}) => {
  // Define border color classes based on the borderType
  const borderClass = () => {
    switch (borderType) {
      case InputBorderType.ERROR:
        return "border-red-500 focus:border-red-600 placeholder-red-400";
      case InputBorderType.SUCCESS:
        return "border-green-500 focus:border-green-600";
      default:
        return "border-gray-300 focus:border-gray-400";
    }
  };

  return (
    <input
      onChange={onChange}
      className={`p-2 bg-transparent rounded-md text-inherit outline-none ${borderClass()} border transition-colors duration-300 ${className}`}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export { InputComponent };
