export enum InputBorderType {
  DEFAULT = "default",
  ERROR = "error",
  SUCCESS = "success",
}

export interface WaitlistFormData {
  email: string;
  name: string;
  companyName: string;
  companySize: string;
  otherInformation: string;
}

export enum WaitlistRoles {
  DESIGNER = "Designer",
  DEVELOPER = "Developer",
  PRODUCT = "Product",
  OTHER = "Other",
}
