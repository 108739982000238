import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  DocumentReference,
  Firestore,
  Timestamp,
  initializeFirestore,
} from "firebase/firestore";
import { firebaseConfig } from "../Config/firebase";
import { WaitlistFormData } from "../Types/globalInterfaces";

export class FirebaseService {
  private db: Firestore;

  constructor() {
    const app = initializeApp(firebaseConfig);
    this.db = initializeFirestore(app, {
      experimentalForceLongPolling: true,
    });
  }

  public async retryRequest(call: () => Promise<any>): Promise<any> {
    let retries = 3;
    while (retries > 0) {
      try {
        return call();
      } catch (e) {
        console.error("Error during request: ", e);
        retries -= 1;
        if (retries === 0) {
          throw new Error("Error after multiple attempts");
        }
        // Wait before retrying
        await new Promise((res) => setTimeout(res, 1000));
      }
    }
  }

  public async saveWaitlistData(
    data: WaitlistFormData
  ): Promise<DocumentReference<WaitlistFormData>> {
    return this.retryRequest(async () => {
      const docRef = await addDoc(collection(this.db, "waitlist"), {
        ...data,
        timestamp: Timestamp.now(),
      });
      return docRef;
    });
  }
}
