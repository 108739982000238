import { Route, Routes, BrowserRouter } from "react-router-dom";
import { HomePage, WaitlistPage } from "./Pages";
import ReactGA from "react-ga4";

function App() {
  // Initialize Google Analytics
  ReactGA.initialize('G-YPN3WJM1E3');

  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/waitlist" element={<WaitlistPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
